import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map, shareReplay } from 'rxjs/operators';
import { ActionResultInteger } from '@portal/models/actionResultInteger';
import { ActionResultPageDisputePaginationShared } from '@portal/models/actionResultPageDisputePaginationShared';
import { ActionResultPageNegotiationShared } from '@portal/models/actionResultPageNegotiationShared';
import { CartSellerSku } from '@portal/models/cartSellerSku';
import { DisputeShared } from '@portal/models/disputeShared';
import { OrderResponse } from '@portal/models/orderResponse';
import {
  disputesContext,
  makerCheckerClientContext,
  negotiationsContext,
  ordersClientContext,
} from '@portal/api-endpoints';
import { PaginatorQuery } from '@portal/components';
import { DEFAULT_PAGE_NUM, DEFAULT_PAGE_SIZE, getHttpParams } from '@portal/core';
import StatusEnum = OrderResponse.StatusEnum;
import DisputeStatusEnum = DisputeShared.DisputeStatusEnum;
import NegotiationStatusEnum = CartSellerSku.NegotiationStatusEnum;

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  constructor(private readonly http: HttpClient) {}

  loadChecksCount(): Observable<number> {
    return this.http.get<ActionResultInteger>(makerCheckerClientContext.checksCount).pipe(
      shareReplay(1),
      map(response => response.value),
    );
  }

  loadOrdersCount(): Observable<number> {
    return this.http
      .get<ActionResultInteger>(ordersClientContext.countBadge, {
        params: getHttpParams({ statusList: [StatusEnum.CREATED, StatusEnum.ASSEMBLY] }),
      })
      .pipe(
        shareReplay(1),
        map(response => response.value),
      );
  }

  loadDisputesCount(): Observable<number> {
    return this.http
      .get<ActionResultPageDisputePaginationShared>(disputesContext.disputes, {
        params: getHttpParams({
          status: DisputeStatusEnum.NEW,
          'page.num': DEFAULT_PAGE_NUM,
          'page.size': DEFAULT_PAGE_NUM,
        } as PaginatorQuery),
      })
      .pipe(
        shareReplay(1),
        map((res: ActionResultPageDisputePaginationShared) => res.value.totalCount),
      );
  }

  loadNegotiationsCount(): Observable<number> {
    return this.http
      .get<ActionResultPageNegotiationShared>(negotiationsContext.get, {
        params: getHttpParams({
          status: NegotiationStatusEnum.STARTED,
          'page.num': DEFAULT_PAGE_NUM,
          'page.size': DEFAULT_PAGE_SIZE,
        } as PaginatorQuery),
      })
      .pipe(
        shareReplay(1),
        map(r => r.value.totalCount),
      );
  }
}
