const api = '/item-service/api/external';

export const productsContext = {
  products: `${api}/items`,
  productsWithSeller: `/web-api/items`,
  getProduct: `${api}/items/`,
  getProducts: `${api}/items`,
  postProduct: `${api}/items`,
  putCharacteristics: `${api}/items/{id}/characteristics`,
  putProduct: `${api}/items/{id}`,
  addAttachment: `${api}/items/{id}/attachments`,
  changeStatus: `${api}/items/status`,
  productSku: `${api}/items/{id}/sku`,
  putSku: `${api}/items/{id}/sku/{skuId}`,
  postSku: `${api}/items/{id}/sku`,
  deleteSku: `${api}/items/sku`,
  productSettings: `${api}/items/fillingSettings`,
  categories: `${api}/views/structure`,
  remove: `${api}/items/sku`,
  block: `${api}/items/{id}/operatorBlock`,
  allCategories: `${api}/categories`,
  sellerCategories: `${api}/categories/seller`,
  uploadImage: `${api}/items/{id}/images`,
  updateImage: `${api}/images/{id}`,
  deleteImage: `${api}/images/{id}`,
  reorganizeImageOrder: `${api}/images/{id}`,
  postTags: `${api}/items/tags`,
  deleteTags: `${api}/items/tags`,
  deleteTag: `${api}/items/{id}/tags/{tagId}`,
  regions: `${api}/items/region`,
  brandingOptions: `${api}/brands`,
  withdrawCheck: `${api}/items/{id}/withdrawCheck`,
  catalog: {
    deleteItem: `${api}/catalog/{id}/item/{temporaryItemId}`,
    download: `${api}/catalog`,
    getItems: `${api}/catalog/{id}/items`,
    processUploading: `${api}/catalog/{id}`,
    updateItem: `${api}/catalog/{id}/item/{temporaryItemId}`,
    upload: `${api}/catalog`,
    byCategory: `${api}/catalog/template`,
    priceAndStocks: '/item-service/api/external/catalog/priceTemplate',
    sortOrderTemplate: '/item-service/api/external/catalog/sortOrderTemplate',
    multiLangCatalog: '/item-service/api/external/catalog/multiLang',
    multiLangCatalogTemplate: '/item-service/api/external/catalog/multiLangTemplate',
  },
  price: {
    deleteSku: `${api}/catalog/{id}/sku/{temporarySkuId}`,
    download: `${api}/sku`,
    getSku: `${api}/catalog/{id}/sku`,
    updateSku: `${api}/catalog/{id}/sku/{temporarySkuId}`,
  },
  paymentAttribute: {
    getAll: `${api}/paymentAttributes`,
    getForProducts: `${api}/items/paymentAttributes`,
    update: `${api}/items/{id}/paymentAttributes`,
    delete: `${api}/items/{id}/paymentAttributes`,
  },
  paymentPlan: {
    update: `${api}/items/{id}/paymentPlans`,
    remove: `${api}/items/{id}/paymentPlans`,
  },
  margin: `${api}/items/{id}/margin`,
  units: `${api}/units`,
  updateSortOrder: `${api}/items/{id}/sortOrder`,
  relatedItems: `${api}/items/related/{id}`,
};
