import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { AuthService } from '../auth.service';
import { clientContext } from '../client.context';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(private readonly auth: AuthService) {}

  intercept(request: HttpRequest<object>, next: HttpHandler): Observable<HttpEvent<object>> {
    if (this.isProhibitedUrl(request.url)) {
      return next.handle(request);
    }
    request = request.clone({
      setHeaders: {
        Authorization: this.auth.getAuthHeader(),
      },
    });

    return next.handle(request);
  }

  private isProhibitedUrl(url: string) {
    // User-service check authorization for each request and when we want
    // to refresh token which is no longer valid we have to omit Bearer here
    return url.includes(clientContext.token);
  }
}
