/**
 * Cart service API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * 
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CartDeliveryMethod } from './cartDeliveryMethod';
import { CartSku } from './cartSku';
import { SystemComment } from './systemComment';

export interface CartSellerSku { 
    id?: number;
    cartId?: number;
    sellerId?: number;
    legalSeller?: boolean;
    negotiationStatus?: CartSellerSku.NegotiationStatusEnum;
    sku?: { [key: string]: CartSku; };
    buyerComment?: string;
    systemComment?: SystemComment;
    cartDeliveryMethod?: CartDeliveryMethod;
    promocode?: string;
    errorMessage?: string;
}
export namespace CartSellerSku {
    export type NegotiationStatusEnum = 'NOT_EXIST' | 'STARTED' | 'FINISHED' | 'CANCELLED' | 'ABORTED';
    export const NegotiationStatusEnum = {
        NOTEXIST: 'NOT_EXIST' as NegotiationStatusEnum,
        STARTED: 'STARTED' as NegotiationStatusEnum,
        FINISHED: 'FINISHED' as NegotiationStatusEnum,
        CANCELLED: 'CANCELLED' as NegotiationStatusEnum,
        ABORTED: 'ABORTED' as NegotiationStatusEnum
    };
}