const api = '/order-service/rest/api/external/tenders';

export const tendersContext = {
  all: `${api}/requests`,
  byId: `${api}/requests/{id}`,
  allStatuses: `${api}/statuses`,
  tenderItems: `${api}/requests/{id}/items`,
  tenderItem: `${api}/items/{id}`,
  updateTenderItem: `${api}/items`,
  updateTender: `${api}/requests`,
  offersById: `${api}/offers/{id}`,
  offersPages: `${api}/offers/page`,
  createOffer: `${api}/offers`,
  getSubscribeCategories: `/item-service/api/external/tenders/subscriptions/bySeller/{id}`,
  subscribeToCategory: `/item-service/api/external/tenders/subscriptions`,
  uploadAttachment: `${api}/attachments/byTender/{id}`,
  deleteAttachment: `${api}/attachments/{id}`,
  confirmDeal: `${api}/offers/createOrder`,
  rejectDeal: `${api}/offers/{id}/reject`,
};
