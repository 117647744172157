import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { filter, switchMap, take } from 'rxjs/operators';
import { ActionResultUserAuthenticateResponse } from '@portal/models/actionResultUserAuthenticateResponse';
import { AuthService } from '../auth.service';
import { clientContext } from '../client.context';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private readonly refreshTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(private readonly auth: AuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if ((!this.auth.isTokenExpired && !this.auth.isTokenRefreshExpired) || request.url.includes(clientContext.token)) {
      return next.handle(request);
    }

    if (this.auth.isTokenExpired && !this.auth.isTokenRefreshExpired && !this.refreshTokenInProgress) {
      this.refreshTokenInProgress = true;
      this.refreshTokenSubject.next(null);

      return this.auth.refreshAccessToken(this.auth.refreshToken.getValue()).pipe(
        switchMap((res: ActionResultUserAuthenticateResponse) => {
          this.refreshTokenInProgress = false;
          this.refreshTokenSubject.next(res.value?.token);

          return next.handle(this.addToken(request));
        }),
      );
    } 
      return this.refreshTokenSubject.pipe(
        filter(result => result !== null),
        take(1),
        switchMap(() => next.handle(this.addToken(request))),
      );
    
  }

  addToken(request: HttpRequest<any>) {
    return request.clone({
      setHeaders: {
        Authorization: this.auth.getAuthHeader(),
      },
    });
  }
}
