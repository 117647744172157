import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { of , timer , zip } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import equal from 'fast-deep-equal/es6';
import _moment from 'moment';
import { ActionResultBoolean } from '@portal/models/actionResultBoolean';
import { ActionResultUserAuthenticateResponse } from '@portal/models/actionResultUserAuthenticateResponse';
import { ActionResultUserRecoverPasswordSupplyResponse } from '@portal/models/actionResultUserRecoverPasswordSupplyResponse';
import { ActionResultVoid } from '@portal/models/actionResultVoid';
import { ForgotPasswordAnonymousRequest } from '@portal/models/forgotPasswordAnonymousRequest';
import { ForgotPasswordRequest } from '@portal/models/forgotPasswordRequest';
import { JwtSecurityUser } from '@portal/models/jwtSecurityUser';
import { LegalSellerReference } from '@portal/models/legalSellerReference';
import { ResetPasswordByTokenRequest } from '@portal/models/resetPasswordByTokenRequest';
import { RoleSaveRequest } from '@portal/models/roleSaveRequest';
import { UserAuthenticateResponse } from '@portal/models/userAuthenticateResponse';
import { UserChangePasswordRequest } from '@portal/models/userChangePasswordRequest';
import { UserRecoverPasswordCompleteRequest } from '@portal/models/userRecoverPasswordCompleteRequest';
import { UserRecoverPasswordInitRequest } from '@portal/models/userRecoverPasswordInitRequest';
import { getHttpParams, replaceId } from '@portal/core';
import { AGENT_TYPE, PRIVILEGES_KEY, PROFILE_TYPE_KEY, TOKEN_KEY } from './auth.constants';
import { AuthRequest, LogInResponse, OAuthAccessToken, UserWithPrivileges } from './auth.interfaces';
import { clientContext } from './client.context';
import ProfileTypeEnum = RoleSaveRequest.ProfileTypeEnum;
import StatusSellerEnum = LegalSellerReference.StatusEnum;
import LegalSellerStatusEnum = LegalSellerReference.LegalSellerStatusEnum;
import { rshbIntegrationContext } from '@portal/api-endpoints';
import { LocationStorageService } from './location-storage.service';

const helper = new JwtHelperService();

/* eslint-disable no-underscore-dangle */

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  refreshToken: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  get isTokenExpired(): boolean {
    if (!this.token) {
      return false;
    }

    return helper.isTokenExpired(this.token) || this.isTokenAlmostExpired();
  }

  private get token(): string | undefined {
    if (this._token) {
      return this._token;
    }

    const context = localStorage.getItem(TOKEN_KEY);

    return context
      ? (this._token = JSON.parse(context)) // cache
      : undefined;
  }

  private set token(token: string | undefined) {
    this._token = token;

    if (token) {
      localStorage.setItem(TOKEN_KEY, JSON.stringify(token));
    } else {
      localStorage.removeItem(TOKEN_KEY);
    }
  }

  private set privileges(privileges) {
    this._privileges = privileges;

    if (privileges) {
      localStorage.setItem(PRIVILEGES_KEY, privileges);
    } else {
      localStorage.removeItem(PRIVILEGES_KEY);
    }
  }

  private get privileges() {
    if (!this._privileges) {
      this._privileges = localStorage.getItem(PRIVILEGES_KEY);
    }

    return this._privileges;
  }

  private set profileType(profileType: ProfileTypeEnum) {
    this._profileType = profileType;

    if (profileType) {
      localStorage.setItem(PROFILE_TYPE_KEY, profileType);
    } else {
      localStorage.removeItem(PROFILE_TYPE_KEY);
    }
  }

  private get profileType(): ProfileTypeEnum {
    if (!this._profileType) {
      this._profileType = localStorage.getItem(PROFILE_TYPE_KEY) as ProfileTypeEnum;
    }

    return this._profileType;
  }

  get isAuthenticated(): boolean {
    return !!this.parsedToken;
  }

  redirectUrl?: string;

  parsedToken?: OAuthAccessToken;

  // tslint:disable-next-line:variable-name
  private _token?: string;
  // TODO add tests for privileges
  // tslint:disable-next-line:variable-name
  private _privileges?: string;
  // tslint:disable-next-line:variable-name
  private _profileType?: ProfileTypeEnum;

  private readonly userInfo = new BehaviorSubject<UserWithPrivileges>(undefined);

  constructor(
    private readonly http: HttpClient,
    private readonly injector: Injector,
    private readonly router: Router,
    private readonly locationStorage: LocationStorageService,
  ) {
    if (this.token) {
      this.initJWT();
    }
    this.setUserInfo();
    this.setRefreshToken();
  }

  getAuthHeader() {
    return this.token ? `Bearer ${this.token}` : '';
  }

  setUserInfo(): void {
    if (!this.token || !this.privileges) {
      return;
    }

    const user: JwtSecurityUser = JSON.parse(this.parsedToken.user);
    this.userInfo.next({
      ...user,
      privileges: JSON.parse(this.privileges),
      profileType: this.profileType || ProfileTypeEnum.OPERATOR,
      sellerId: this.getSellerId(user),
      sellerReferenceId: this.getReferenceSellerId(user),
    });
  }

  getUserInfo(): Observable<UserWithPrivileges> | undefined {
    return this.userInfo.asObservable().pipe(
      filter(v => !!v),
      distinctUntilChanged(equal),
    );
  }

  // #region logIn/logOut
  logIn(user: AuthRequest, checkCaptcha?: boolean): Observable<LogInResponse> {
    // TODO: figure out which way will be better to hold errors
    // --it may be a separate notification service, that will show all errors in specific part of UI
    // --it may be a special logger that will send notifications to the server
    // or just log it to the console in development mode
    const url = checkCaptcha ? clientContext.tokenWithCaptcha : clientContext.token;
    const request: AuthRequest = { ...user, login: user.login.trim(), password: user.password.trim() };
    this.profileType = user.agentType;

    return this.http
      .post<ActionResultUserAuthenticateResponse>(url, request, {
        params: getHttpParams({ ...(checkCaptcha ? {} : { skipErrorHandling: true }) }),
      })
      .pipe(this.handleLogin());
  }

  ssoLogin(token: string): Observable<LogInResponse> {
    return this.http.post(rshbIntegrationContext.merchantSsoLogin, token).pipe(this.handleLogin());
  }

  changePassword(
    newPassword: string,
    oldPassword: string,
    agentType: ProfileTypeEnum,
    skipErrorHandling = false,
  ): Observable<LogInResponse | Error> {
    return this.getUserInfo().pipe(
      switchMap((u: UserWithPrivileges) =>
        this.http
          .put<ActionResultBoolean>(
            clientContext.changePassword,
            {
              newPassword,
              oldPassword,
              userId: u.id,
            } as UserChangePasswordRequest,
            {
              params: getHttpParams({ ...(skipErrorHandling ? { skipErrorHandling } : {}) }),
            },
          )
          .pipe(switchMap(() => this.logIn({ login: u.login, password: newPassword, agentType }))),
      ),
    );
  }

  changePasswordWithToken(data: ResetPasswordByTokenRequest, userId: number) {
    const url = replaceId(clientContext.changePasswordWithToken, userId);

    return this.http.put<ActionResultBoolean>(url, data);
  }

  initResetPasswordMobile(data: UserRecoverPasswordInitRequest, agentType: ProfileTypeEnum) {
    return this.http.post<ActionResultUserRecoverPasswordSupplyResponse>(
      clientContext.initResetPasswordMobile,
      {
        ...data,
        agentType,
      },
      // { params: getHttpParams({ skipErrorHandling: true }) },
    );
  }

  resetPasswordMobile(data: UserRecoverPasswordCompleteRequest) {
    return this.http.post(clientContext.completeResetPasswordMobile, data);
  }

  logOut(manual?: boolean): void {
    this.token = undefined;
    this.privileges = undefined;
    this.profileType = undefined;
    this.locationStorage.store(manual ? '/' : this.router.url);
    this.userInfo.next(null);

    this.router.navigate(['auth']);
  }

  // #endregion

  refreshAccessToken(value: string): Observable<ActionResultUserAuthenticateResponse> {
    return this.http
      .put(clientContext.token, value, {
        params: getHttpParams({ skipLoaderLock: true }),
      })
      .pipe(
        catchError(e => {
          this.logOut();

          return e;
        }),
        tap((token: ActionResultUserAuthenticateResponse) => {
          this.setTokenAndPrivileges(token.value);
        }),
      );
  }

  setRefreshTimer(): void {
    const isTokenExpired = helper.isTokenExpired(this.token);
    const isTokenRefreshExpired = this.isTokenRefreshExpired;

    if (isTokenExpired && isTokenRefreshExpired) {
      this.logOut();

      return;
    }

    this.refreshToken.next(this.token);
  }

  initResetPassword(data: ForgotPasswordRequest, agentType: ProfileTypeEnum): Observable<ActionResultVoid> {
    return this.http.post<ActionResultVoid>(clientContext.initResetPassword, { ...data, agentType });
  }

  initResetPasswordAnonymous(
    data: ForgotPasswordAnonymousRequest,
    agentType: ProfileTypeEnum,
  ): Observable<ActionResultVoid> {
    return this.http.post<ActionResultVoid>(clientContext.initResetPasswordAnonymous, { ...data, agentType });
  }

  private isUserBlocked(user: UserWithPrivileges): boolean {
    const agentType = this.injector.get(AGENT_TYPE);

    if (
      agentType === ProfileTypeEnum.LEGALSELLER &&
      [ProfileTypeEnum.LEGALSELLER, ProfileTypeEnum.COMMONSELLER].includes(this.profileType)
    ) {
      return this.profileType === ProfileTypeEnum.LEGALSELLER
        ? user?.legalSellerProfile.status === StatusSellerEnum.DELETED ||
            user?.legalSellerProfile.legalSellerStatus === LegalSellerStatusEnum.BLOCKED
        : user?.commonSellerProfile.status === StatusSellerEnum.DELETED;
    }

    return false;
  }

  private handleLogin(): (source: Observable<ActionResultUserAuthenticateResponse>) => Observable<LogInResponse> {
    return (source: Observable<ActionResultUserAuthenticateResponse>) =>
      source.pipe(
        tap((data: ActionResultUserAuthenticateResponse) => {
          this.setTokenAndPrivileges(data.value);
          this.setUserInfo();
        }),
        switchMap((data: ActionResultUserAuthenticateResponse) => zip(this.getUserInfo(), of(data))),
        map(([userInfo, result]) => ({
          ...result,
          isUserBlocked: this.isUserBlocked(userInfo),
        })),
        tap(({ isUserBlocked }) => {
          this.navigateOrLogout(isUserBlocked);
        }),
      );
  }

  private setTokenAndPrivileges(data: UserAuthenticateResponse): void {
    this.token = data.token;
    this.privileges = JSON.stringify(data.privileges);
    // JWT should be initialized manually, due to the fact that service has already started
    this.initJWT();
  }

  private initJWT(): void {
    this.parsedToken = helper.decodeToken(this.token);
    this.setRefreshTimer();
  }

  private setRefreshToken(): void {
    const TEN_SECONDS = 10000; // lag for request time
    this.refreshToken
      .asObservable()
      .pipe(
        filter((v: string) => !!v),
        distinctUntilChanged(),
        switchMap(v =>
          timer(helper.getTokenExpirationDate(v).valueOf() - new Date().valueOf() - TEN_SECONDS).pipe(map(_ => v)),
        ),
        switchMap((v: string) => this.refreshAccessToken(v)),
      )
      .subscribe();
  }

  // Solution for multipart forms with big data
  private isTokenAlmostExpired(seconds = 2): boolean {
    const end = _moment(helper.getTokenExpirationDate(this.token).getTime());
    const now = _moment(Date.now());
    const diff = _moment.duration(end.diff(now)).asSeconds();

    return diff <= seconds;
  }

  get isTokenRefreshExpired(): boolean {
    const MILLISECONDS = 1000;
    const end = _moment(this.parsedToken?.rtexp * MILLISECONDS);
    const now = _moment(Date.now());
    const diff = _moment.duration(end.diff(now)).asSeconds();

    return diff < 0;
  }

  private navigateOrLogout(blocked: boolean): void {
    if (!blocked) {
      this.locationStorage.restore();

      return;
    }
    this.logOut();
  }

  private getSellerId(user: JwtSecurityUser): number {
    switch (this.profileType) {
      case ProfileTypeEnum.LEGALSELLER:
        return user.legalSellerProfile?.referenceId || user.legalSellerProfile?.companyId;
      case ProfileTypeEnum.COMMONSELLER:
        return user.commonSellerProfile?.id;
      default:
        return user.legalSellerProfile?.companyId;
    }
  }

  private getReferenceSellerId(user: JwtSecurityUser): number | null {
    switch (this.profileType) {
      case ProfileTypeEnum.LEGALSELLER:
        return user.legalSellerProfile?.referenceId;
      default:
        return null;
    }
  }
}
